<template>
  <div class="event page" id="FitterPage">
    <div class="content" v-loading="loading">
      <div class="page_header" v-if="event">
        <el-button icon="el-icon-back" @click="$router.go(-1)" size="mini"></el-button>
        <h2 style="display:inline-block; vertical-align:top; margin-top: 2px;margin-left: 10px;">
          Заявка на
          <template v-if="event.type === 'assessment'">замер</template>
          <template v-else-if="event.type === 'mounting'">монтаж</template>
        </h2>
      </div>
      <div class="box" v-if="event">
        <div class="time">
          <i class="el-icon-time"></i>
          {{ moment(event.start_date).format('DD.MM.YYYY') }}
          с {{ moment(event.start_time, 'HH:mm:ss').format('HH:mm') }}
          до {{ moment(event.end_time, 'HH:mm:ss').format('HH:mm') }}
        </div>
        <div class="manager">
          Менеджер: {{ event.request.employee.fio_short }}
        </div>
        <div class="address">
          Адрес: {{ event.address.value }}
        </div>
        <div class="manager_comment">
          Комментарий менеджера: {{ event.manager_comment }}
        </div>
        <div class="client">
          <h3>Клиент:</h3>
          {{ event.client.name }}
          <div class="contacts" v-if="event.client.contacts">
            <div class="contact" v-for="contact in event.client.contacts">
              <el-link
                  :href="'tel:+7' + contact.formatted"
                  class="phone_link"
                  icon="el-icon-phone"
                  v-if="contact.type == 'phone'"
                  :underline="false">{{ contact.value }}
              </el-link>
            </div>
          </div>
        </div>
      </div>

      <div class="box" v-if="event">
        <div class="actions">

          <div class="comment">
            <el-input
                type="textarea"
                v-if="event.departed != null"
                v-model="event.fitter_comment"
                placeholder="Комментарий"
                size="mini"
                :autosize="{ minRows: 2 }"
                @change="changeEvent"
            ></el-input>
          </div>

          <div class="loaded_files" v-if="event.files">
            <div class="file-item" v-for="file in event.files">
              <div class="element">
                <el-image v-if="file.ext == 'jpeg' || file.ext == 'jpg' || file.ext == 'png'"
                          style="width: 80px;height: 80px;"
                          class="image"
                          fit="cover"
                          :src="'https://online.lbsteklo.ru/files/'+file.file"
                          :preview-src-list="['https://online.lbsteklo.ru/files/'+file.file]"
                ></el-image>
                <div v-else class="icon" @click="openFile(file.file)">
                  <v-icon scale="5" v-if="file.ext == 'pdf'" name="file-pdf"></v-icon>
                  <v-icon scale="5" v-else-if="file.ext == 'doc' || file.ext == 'docx'" name="file-word"></v-icon>
                  <v-icon scale="5" v-else-if="file.ext == 'xls' || file.ext == 'xlsx' || file.ext == 'XLS'"
                          name="file-excel"></v-icon>
                  <v-icon scale="5" v-else-if="file.ext == 'dwg'" name="unity"></v-icon>
                  <v-icon scale="5" v-else-if="file.ext == 'zip'" name="file-archive"></v-icon>
                  <v-icon scale="5" v-else name="file"></v-icon>
                </div>
                <span class="title">{{file.title}}</span>
              </div>
            </div>
          </div>

          <div class="files" v-if="event.arrived != null" v-loading="fileUploading">
            <div class="element">
              <el-upload
                  class="upload-file"
                  action="https://online.lbsteklo.ru/v21/calendar/uploadFiles/"
                  :show-file-list="false"
                  :data="{eventId: event.id}"
                  :headers="{Authorization: getLocalToken()}"
                  :on-success="successFileUpload"
                  :on-progress="uploading"
                  multiple>
                <el-button size="small" type="primary" icon="el-icon-upload" :disabled="fileUploading">Загрузить фотографии</el-button>
              </el-upload>
            </div>
          </div>


          <el-button
              type="primary"
              size="small"
              v-if="event.departed == null"
              :disabled="submitting"
              @click="departed"
          >Выезжаю
          </el-button>
          <el-button
              type="primary"
              size="small"
              v-else-if="event.arrived == null"
              :disabled="submitting"
              @click="arrived"
          >На месте, начинаем
            <template v-if="event.type === 'assessment'">замер</template>
            <template v-else-if="event.type === 'mounting'">монтаж</template>
          </el-button>
          <el-button
              type="primary"
              size="small"
              v-else-if="event.finished == null"
              :disabled="submitting"
              @click="finished"
          >
            <template v-if="event.type === 'assessment'">Замер выполнен</template>
            <template v-else-if="event.type === 'mounting'">Монтаж выполнен</template>
          </el-button>
        </div>
      </div>

      <div class="box" v-if="event && event.order">
        <div class="order">
          <h3>Информация по заказу {{ event.order.number }}</h3>
          <div class="comment">{{ event.order.comment }}</div>

          <div class="positions">
            <table class="table">
              <thead>
              <tr>
                <td>Наименование</td>
                <td>Кол-во</td>
              </tr>
              </thead>
              <tr v-for="p in event.order.positions">
                <td>{{ p.assortment.title }}</td>
                <td>{{ p.qty | qty(p.uom.decimal_places) }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as calendarEvent} from "@/store/modules/calendar";
import {getItem} from "@/helpers/persistanceStorage";

import qty from "@/filters/qty";

export default {
  name: "Event",
  data() {
    return {
      fileUploading: false
    }
  },
  filters: {
    qty
  },
  computed: {
    ...mapState({
      event: state => state.calendar.current,
      loading: state => state.calendar.loading,
      submitting: state => state.calendar.submitting
    }),
    id() {
      return Number(this.$route.params.id)
    }
  },
  methods: {
    loadData() {
      this.$store.dispatch(calendarEvent.getById, this.id)
    },
    departed() {
      this.$store.dispatch(calendarEvent.changeStatus, {id: this.id, type: 'departed'}).then(() => {
        this.loadData()
      })
    },
    arrived() {
      this.$store.dispatch(calendarEvent.changeStatus, {id: this.id, type: 'arrived'}).then(() => {
        this.loadData()
      })
    },
    finished() {
      this.$store.dispatch(calendarEvent.changeStatus, {id: this.id, type: 'finished'}).then(() => {
        this.loadData()
      })
    },
    changeEvent() {
      this.$store.dispatch(calendarEvent.save, this.event)
    },
    getLocalToken() {
      const token = getItem('accessToken')
      return token ? `Token ${token}` : ''
    },
    successFileUpload() {
      this.fileUploading = false
      this.loadData()
    },
    uploading(e,f,l){
      this.fileUploading = true
    }
  },
  mounted() {
    this.loadData()
  }

}
</script>
